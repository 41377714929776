<template>
  <div class="jgg-lottery">
    <titlebar :titleName="activity_name"></titlebar>
    <div class="content1" :style="{ height: '245vw' }">
      <div class="component_popup" v-if="islogin === 2">
        <div class="popup_mask"></div>
        <div class="popup_content">
          <login @isLogin="isLogin" @getNum="getNum"></login>
        </div>
      </div>
      <div class="jggcontent"></div>
      <LuckyGrid
        width="80vw"
        height="80vw"
        ref="LuckyGrid"
        :blocks="blocks"
        :prizes="prizes"
        :buttons="buttons"
        :activeStyle="activeStyle"
        :defaultStyle="defaultStyle"
        :defaultConfig="defaultConfig"
        @start="startCallBack"
        @end="endCallBack"
        class="jggbb"
      />
      <div class="balloon"></div>
      <!-- 活动规则 -->
      <div class="jggboard">
        <p class="text">
          您还有
          <span class="num">{{ num }}</span>
          次抽奖机会
        </p>
        <div class="jggtext1" v-html="rule"></div>
      </div>
      <div
        class="jggbtn"
        @click="
          showRecord();
          getPrizeRecord();
        "
      >
        <p class="text">我的中奖记录</p>
      </div>
      <!-- 中奖记录 -->
      <div class="component_popup" v-if="isshowRecord" @click="showRecord">
        <div class="popup_mask"></div>
        <div class="popup_content">
          <div class="content_box">
            <ol class="prize_list">
              <li
                class="prize_record"
                v-for="(item, index) in prizeRecord"
                :key="item.add_time"
              >
                {{ item.prize_name }} {{ item.add_time }}
              </li>
            </ol>
          </div>
          <div class="content_close" @click="showRecord"></div>
        </div>
      </div>
      <!-- 得奖弹窗 -->
      <div class="component_popup" v-if="isshowPrize" @click="showPrize">
        <div class="popup_mask"></div>
        <div class="popup_content">
          <div class="content_box2">
            <div class="prize_photo">
              <img :src="imgUrl" width="284px" height="215px" />
            </div>
            <div class="prize_name">{{ prize_name }}</div>
          </div>
          <div class="content_close2" @click="showPrize"></div>
        </div>
      </div>
      <!-- 无奖弹窗 -->
      <div class="component_popup" v-if="isshowNoPrize">
        <div class="popup_mask" @click="showNoPrize"></div>
        <div class="popup_content">
          <div class="content_box3">
            <div class="noprize_btn" @click="showNoPrize"></div>
            <div class="noprize_text">很遗憾，大奖与您擦肩而过</div>
          </div>
        </div>
      </div>
      <!-- 登录 -->
      <div class="logintext">
        <span v-if="$cookies.get('uname')">你好，{{ $cookies.get("muname") }} </span>
        <span v-else @click="login">你好，请 登录</span>
        <span v-if="$cookies.get('uname')" @click="out"> 退出</span>
      </div>
    </div>
  </div>
</template>

<script>
import titlebar from "../components/index/titleBar.vue";
import { LuckyGrid } from "@lucky-canvas/vue";
import apiFun from "../servers/apiFun";
import comTool from "../assets/js/until";
import login from "./login";

export default {
  components: {
    titlebar,
    LuckyGrid,
    login,
  },
  data() {
    return {
      // mmh: "",
      num: $cookies.get("num"),
      data: {},
      islogin: 0,
      draw_type_id: "",
      activity_id: "",
      isshowRecord: false,
      isshowPrize: false,
      isshowNoPrize: false,
      imgUrl: "",
      prizeRecord: "",
      prize_name: "",
      rule: "",
      activity_name: "",
      blocks: [{ padding: "2vw" }],
      prizes: [
        {
          x: 0.05,
          y: 0,
          imgs: [
            {
              src: require("../assets/images/jggprize1.png"),
              width: "70%",
              top: "22%",
            },
          ],
        },
        {
          x: 1,
          y: 0,
          imgs: [
            {
              src: require("../assets/images/jggprize2.png"),
              width: "60%",
              top: "19%",
            },
          ],
        },
        {
          x: 2,
          y: 0,
          imgs: [
            {
              src: require("../assets/images/jggprize3.png"),
              width: "48%",
              top: "20%",
            },
          ],
        },
        {
          x: 1.95,
          y: 1,
          imgs: [
            {
              src: require("../assets/images/jggprize4.png"),
              width: "60%",
              top: "16%",
            },
          ],
        },
        {
          x: 1.955,
          y: 2.01,
          fonts: [
            {
              text: "",
              top: "20%",
              fontColor: "#ffffff",
              fontSize: "22px",
              wordWrap: false,
              fontStyle: "Source Han Sans CN",
            },
          ],
        },
        {
          x: 1,
          y: 2,
          imgs: [
            {
              src: require("../assets/images/jggprize5.png"),
              width: "46%",
              top: "10%",
            },
          ],
        },
        {
          x: 0.05,
          y: 2,
          imgs: [
            {
              src: require("../assets/images/jggprize6.png"),
              width: "60%",
              top: "10%",
            },
          ],
        },
        {
          x: 0.05,
          y: 1,
          imgs: [
            {
              src: require("../assets/images/jggprize7.png"),
              width: "46%",
              top: "10%",
            },
          ],
        },
      ],
      buttons: [
        {
          x: 1,
          y: 1,
          //   background: "#7f95d1",
          //   fonts: [{ text: "开始", top: "25%" }],
        },
      ],
      defaultConfig: {
        gutter: "1vw",
        decelerationTime: 1000,
      },
      defaultStyle: {
        borderRadius: "3vw",
        // background: "red",
      },
      activeStyle: {},
    };
  },
  created() {
    this.index = Math.floor(Math.random() * 8) + 1;
    if (this.$route.query.access_token) {
      this.client = "android";
      this.sdkInit();
    } else {
      this.client = "h5";
      this.init();
    }
    if ($cookies.get("uname")) {
      this.islogin = 1;
    }
    // this.mmh = document.documentElement.clientHeight + "px";
  },
  methods: {
    //正常初始化
    init() {
      let data = {
        activity_id: this.$route.query.activity_id ? this.$route.query.activity_id : "1",
      };
      apiFun.Init(data).then((res) => {
        if (res.code == 200) {
          this.rule = res.data.activity.content;
          $cookies.set("activity_id", res.data.activity.activity_id);
          $cookies.set("draw_type_id", res.data.activity.draw_type_id);
          this.activity_id = res.data.activity.activity_id;
          this.draw_type_id = res.data.activity.draw_type_id;
          this.activity_name = res.data.activity.activity_name;
          console.log("activity_name: ", this.activity_name);
          if (res.data.activity.draw_type_id == "1") {
            this.$router.push({
              path: "/lottery",
              query: { activity_id: $cookies.get("activity_id") },
            });
          } else if (res.data.activity.draw_type_id == "3") {
            this.$router.push({
              path: "/lotteryndj",
              query: { activity_id: $cookies.get("activity_id") },
            });
          }
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    //sdk初始化
    sdkInit() {
      $cookies.set("pid", this.$route.query.pid, "7d");
      $cookies.set("gid", this.$route.query.gid, "7d");
      $cookies.set("access_token", this.$route.query.access_token, "7d");
      $cookies.set("uname", this.$route.query.uname, "7d");
      this.init();
      this.sdkDrawTimes();
    },
    //sdk获取抽奖次数
    sdkDrawTimes() {
      let data = {
        pid: $cookies.get("pid"),
        gid: $cookies.get("gid"),
        access_token:
          $cookies.get("access_token") == null
            ? $cookies.get("user_token")
            : $cookies.get("access_token"),
        activity_id: this.activity_id,
        client: this.client,
      };
      apiFun.SdkDrawTimes(data).then((res) => {
        $cookies.set("num", res.data.count, "7d");
      });
    },
    startCallBack() {
      if (
        this.num > 0 &&
        this.isshowRecord === false &&
        this.isshowPrize === false &&
        this.islogin === 1
      ) {
        this.num -= 1;
        // 开始游戏
        this.$refs.LuckyGrid.play();
        // 假设接口的请求速度是5s
        this.getData();
        setTimeout(() => {
          // 5s后拿到后端返回的中奖索引
          const prize_id = $cookies.get("id");
          // 然后停止游戏 (缓慢停止)
          this.$refs.LuckyGrid.stop(prize_id);
        }, 5000);
      } else if (
        this.isshowRecord === false &&
        this.isshowPrize === false &&
        this.islogin === 0
      ) {
        this.login();
      } else if (this.num == 0 && this.islogin === 1) {
        this.$toast.fail("剩余抽奖次数为 0 ，不能再抽奖了哦");
      }
    },
    endCallBack(prize) {
      // 当完全停止时, 触发回调函数
      if ($cookies.get("id") == "4") {
        this.isshowNoPrize = true;
      } else {
        this.isshowPrize = true;
      }
    },
    //抽奖接口
    getData() {
      let data = {
        gid: $cookies.get("gid"),
        pid: $cookies.get("pid"),
        activity_id: this.activity_id,
        draw_type_id: this.draw_type_id,
        uname: $cookies.get("uname"),
        access_token:
          $cookies.get("access_token") == null
            ? $cookies.get("user_token")
            : $cookies.get("access_token"),
        client: this.client,
      };
      apiFun.Start(data).then((res) => {
        if (res.code == 200) {
          this.prize_name = res.data.prize_name;
          $cookies.set("id", res.data.id);
          this.imgUrl = res.data.prize_url;
          $cookies.set("num", res.data.draw_times);
        } else {
          this.$toast.fail(res.msg);
          this.prize_name = res.msg;
          $cookies.set("id", 4);
        }
      });
    },
    //抽奖记录接口
    getPrizeRecord() {
      let data = {
        gid: $cookies.get("gid"),
        pid: $cookies.get("pid"),
        access_token:
          $cookies.get("access_token") == null
            ? $cookies.get("user_token")
            : $cookies.get("access_token"),
        client: this.client,
        uname: $cookies.get("uname"),
      };
      apiFun.PrizeRecord(data).then((res) => {
        if (res.code == 200) {
          this.prizeRecord = res.data;
        }
      });
    },
    showRecord() {
      this.isshowRecord = !this.isshowRecord;
    },
    showPrize() {
      this.isshowPrize = !this.isshowPrize;
    },
    showNoPrize() {
      this.isshowNoPrize = !this.isshowNoPrize;
      console.log("isshowNoPrize", this.isshowNoPrize);
    },
    login() {
      this.islogin = 2;
    },
    isLogin(data) {
      this.islogin = data;
    },
    getNum(data) {
      this.num = data;
      console.log("num=", this.num);
    },
    out() {
      $cookies.remove("access_token");
      $cookies.remove("user_token");
      $cookies.remove("uname");
      $cookies.remove("uid");
      $cookies.remove("pid");
      $cookies.remove("gid");
      $cookies.remove("mobile");
      $cookies.remove("realName");
      $cookies.remove("idNumber");
      $cookies.remove("num");
      this.$router.go(0);
    },
  },
};
</script>

<style>
.van-toast {
  width: 800px;
  min-height: 200px;
  line-height: 100px;
}
</style>
<style lang="less" scoped>
.jgg-lottery {
  overflow: hidden;
  z-index: 999;
}
.content1 {
  margin-top: 13vw;
  background-image: url("../assets/images/jggbanner.png");
  background-repeat: no-repeat;
  background-size: 100vw, 100%;
  image-rendering: -webkit-optimize-contrast;
  .jggcontent {
    width: 88vw;
    height: 88vw;
    background-image: url("../assets/images/jgg.png");
    background-size: 88vw 88vw;
    margin: 0 auto;
    position: relative;
    top: 47vw;
  }
  .jggbb {
    position: relative;
    top: -37vw;
    left: 10vw;
  }
  .text1 {
    position: absolute;
    font-family: Source Han Sans CN;
    color: #6098d1;
    font-size: 54px;
    font-weight: bold;
    width: 341px;
    top: 788px;
    left: 71px;
  }
  .jggboard {
    position: relative;
    background-image: url("../assets/images/jggboard.png");
    background-size: 87.5vw 78.2vw;
    width: 87.5vw;
    height: 78.2vw;
    line-height: 10vw;
    top: -32vw;
    left: 6.6vw;
    .text {
      position: relative;
      left: 25vw;
      color: #ffffff;
      font-size: 4vw;
    }
    .num {
      color: #c0ff00;
      font-size: 5.2vw;
      font-weight: 500;
    }
    .jggtext1 {
      position: relative;
      font-family: Source Han Sans CN;
      color: #dc1912;
      line-height: 80px;
      top: 192px;
      left: 78px;
      font-size: 41px;
      font-weight: 550;
    }
  }
  .jggbtn {
    position: relative;
    background-image: url("../assets/images/jggbtn.png");
    background-size: 60vw 10vw;
    width: 60vw;
    height: 10vw;
    line-height: 10vw;
    top: -27vw;
    left: 19.8vw;
    .text {
      position: relative;
      left: 17vw;
      color: #ffffff;
      font-size: 4vw;
      letter-spacing: 0.5vw;
    }
  }
  .balloon {
    position: absolute;
    background-image: url("../assets/images/jggballoon.png");
    background-size: 466px 978px;
    width: 466px;
    height: 550px;
    top: 2762px;
    left: -233px;
    z-index: 2;
  }
}
.component_popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.popup_mask {
  background-color: #000000;
  opacity: 0.79;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.popup_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content_box {
  background-image: url("../assets/images/prizerecord.png");
  background-size: 1003px 1382px;
  width: 1003px;
  height: 1382px;
  .prize_list {
    position: relative;
    width: 660px;
    top: 540px;
    margin: 0 auto;
    height: 553px;
    overflow-x: scroll;
    overflow-y: scroll;
    list-style: decimal inside;
    .prize_record {
      top: 780px;
      left: 40%;
      margin: 60px auto;
      font-size: 46px;
      font-family: PingFang SC;
      color: #ffffff;
    }
  }
}
.content_close {
  width: 70%;
  height: 200px;
  margin: 0 auto;
}
.content_box2 {
  background-image: url("../assets/images/prize.png");
  background-size: 1003px 1382px;
  width: 1003px;
  height: 1382px;
  .prize_name {
    position: relative;
    top: 100px;
    left: 40%;
    margin: 0 auto;
    margin-top: 50px;
    font-size: 46px;
    font-family: PingFang SC;
    color: #ffffff;
  }
  .prize_photo {
    position: relative;
    top: 170px;
    left: 100px;
    font-size: 46px;
    font-family: PingFang SC;
    color: #ffffff;
  }
}
.content_close2 {
  width: 70%;
  height: 200px;
  margin: 0 auto;
}
.logintext {
  position: absolute;
  top: 199px;
  left: 40px;
  font-size: 46px;
  font-family: PingFang SC;
  color: #ffffff;
}
.content_box3 {
  background-image: url("../assets/images/noprize.png");
  background-size: 869px 1105px;
  width: 869px;
  height: 1105px;
  text-align: center;
  .noprize_text {
    position: relative;
    top: 529px;
    font-size: 59px;
    font-weight: bold;
    font-family: Adobe Heiti Std;
    color: #ffffff;
  }
  .noprize_btn {
    background-image: url("../assets/images/onemore.png");
    background-size: 601px 148px;
    width: 601px;
    height: 148px;
    position: relative;
    top: 867px;
    left: 15%;
  }
}
</style>
